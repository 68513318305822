import React from 'react';
import GameCard from './GameCard';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

export default function BodyCanvas(props) {
    const { games } = props;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {games.map((game) => (
                    <Grid xs={4} key={game.cardTitle}>
                        <GameCard 
                            cardLink={game.cardLink} 
                            cardTitle={game.cardTitle} 
                            cardImage={game.cardImage} 
                            cardDescription={game.cardDescription} 
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
