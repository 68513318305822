import React from 'react';
import BodyCanvas from './BodyCanvas';
import Footer from './Footer';
import Container from '@mui/material/Container';

import constructionGif from '../gif/construction.gif';
import saberKnightGif from '../gif/Saber-Knight.gif';
import theHacklistGif from '../gif/The-Hacklist.gif';
import wildSquadGif from '../gif/Wild-Squad.gif';
import heartHealersGif from '../gif/HeartHealers.gif';
import discDoomGif from '../gif/DiscDoom.gif';
import Header from './Header';

export default function Home(props) {
  const { changeMode } = props;
  const games = [
    { cardLink:"https://play.google.com/store/apps/details?id=com.PakataGames.PutASmile", cardTitle:"Put A Smile", cardImage:constructionGif, cardDescription:"Developed with Unity",  },
    { cardLink:"http://canodrome.com/", cardTitle:"Canodrome", cardImage:constructionGif, cardDescription:"Developed with Unity",  },
    { cardLink:"https://dracobk201.itch.io/disc-doom", cardTitle:"Disc Doom", cardImage:discDoomGif, cardDescription:"Developed with Unity",  },
    { cardLink:"https://play.google.com/store/apps/details?id=com.izrrdev.wildsquad", cardTitle:"Wild Squad", cardImage:wildSquadGif, cardDescription:"Developed with Unity",  },
    { cardLink:"https://causticgames.itch.io/the-hacklist-prologue", cardTitle:"The Hacklist", cardImage:theHacklistGif, cardDescription:"Developed with Unity",  },
    { cardLink:"https://connect.unity.com/p/sabre-knight-fight-for-the-eye", cardTitle:"Saber Knight", cardImage:saberKnightGif, cardDescription:"Developed with Unity",  },
    { cardLink:"http://2013.globalgamejam.org/2013/heart-healers", cardTitle:"Heart Healers", cardImage:heartHealersGif, cardDescription:"Developed with Unity",  },
  ];

  return (
    <Container>
      <Header changeMode={changeMode}/>
      <BodyCanvas games={games}/>
      {/* <Footer/> */}
    </Container>
  );
}