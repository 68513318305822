import React from 'react';
import '../css-files/privacy-policy.css';

export default function PutASmileTermService() {
	
    return (
        <div className="term-service-container">
            <p className='term-service-title'>Terms of Service</p>
            <p className='term-service-subtitle'>Acceptance of Terms</p>
            <p className='term-service-description'>By downloading, installing, or using the game <b>Put A Smile</b> (the "Game"), you agree to these Terms of Service (the "Terms"). If you do not agree to these Terms, you must not download, install, or use the Game.</p>
            <p className='term-service-subtitle'>Grant of License</p>
            <p className='term-service-description'>Subject to these Terms, <b>Pakata Games</b> (the "Developer") grants you a non-exclusive, non-transferable, and revocable license to use the Game on a compatible mobile device.</p>
            <p className='term-service-subtitle'>Restrictions</p>
            <p className='term-service-description'>You may not:</p>
            <ul className='term-service-list'>
                <li className='term-service-item'>Sell, rent, lease, or distribute the Game to third parties.</li>
                <li className='term-service-item'>Modify, decompile, or reverse engineer the Game.</li>
                <li className='term-service-item'>Remove or modify any intellectual property notices from the Game.</li>
                <li className='term-service-item'>Use the Game for any illegal or commercial purpose.</li>
                <li className='term-service-item'>Use the Game in a manner that may infringe the rights of third parties.</li>
            </ul>
            <p className='term-service-subtitle'>Intellectual Property</p>
            <p className='term-service-description'>The Game and all intellectual property rights related thereto are the property of the Developer.</p>
            <p className='term-service-subtitle'>Privacy</p>
            <p className='term-service-description'>The Developer may collect information about your use of the Game. The Developer will use this information in accordance with its Privacy Policy.</p>
            <p className='term-service-subtitle'>Disclaimer</p>
            <p className='term-service-description'>THE GAME IS PROVIDED "AS IS" AND "AS AVAILABLE". THE DEVELOPER MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE GAME. THE DEVELOPER WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE GAME.</p>
            <p className='term-service-subtitle'>Indemnification</p>
            <p className='term-service-description'>You agree to indemnify and defend the Developer from any claim, damage, loss, or expense (including attorneys' fees) arising out of or related to your use of the Game.</p>
            <p className='term-service-subtitle'>Modifications</p>
            <p className='term-service-description'>The Developer may modify these Terms at any time. If the Developer modifies these Terms, it will notify you by a notice in the Game or by email. If you continue to use the Game after the changes to the Terms are posted, you will be deemed to have accepted such changes.</p>
            <p className='term-service-subtitle'>Termination</p>
            <p className='term-service-description'>The Developer may terminate your license to use the Game at any time, for any reason, and without notice. If the Developer terminates your license, you must cease using the Game immediately.</p>
            <p className='term-service-subtitle'>General Provisions</p>
            <p className='term-service-description'>These Terms are governed by the laws of the State of California, USA. If any provision of these Terms is held to be unenforceable, the remaining provisions will remain in full force and effect. These Terms constitute the entire agreement between you and the Developer with respect to the Game.</p>
            <p className='term-service-subtitle'>Contact</p>
            <p className='term-service-description'>If you have any questions about these Terms, you may contact the Developer at the following address:</p>
            <p className='term-service-description'>dracobk201@gmail.com</p>
            <p className='term-service-effectiveness'>Effective Date: February 5, 2024</p>
        </div>
    );
}