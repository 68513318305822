import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

export default function GameCard(props) {
    const {cardLink, cardTitle, cardImage, cardDescription} = props;
	
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image={cardImage}
                    alt={cardTitle}
                />
                <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {cardTitle}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {cardDescription}
                </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button variant="contained" size="small" color="primary" href={cardLink} target='blank'>
                    Open
                </Button>
            </CardActions>
        </Card>
    );
}
