import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { useTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ProfilePicture from '../gif/ProfileMini.png';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export default function Header(props) {
    const { changeMode } = props;
    const theme = useTheme();

    const actions = [
        { name: 'Email', link: "mailto:suberoerick@gmail.com" },
        { name: 'LinkedIn', link: "https://www.linkedin.com/in/ericksubero/" },
        { name: 'Blog', link: "https://ericksubero.wordpress.com/" },
    ];
    const [showActions, setShowActions] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleActionToogle = (event) => {
        setAnchorEl(event.currentTarget);
        setShowActions(!showActions);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">

                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Change Mode">
                            <IconButton sx={{ ml: 1 }} onClick={changeMode} color="inherit">
                                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                    >
                        Erick Subero's Highlighted Projects
                    </Typography>
                    <SportsEsportsIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Box sx={{ flexGrow: 0, position: "absolute", right: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton
                                onClick={handleActionToogle} 
                                id="basic-button"
                                aria-controls={showActions ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={showActions ? 'true' : undefined}
                            >
                                <Avatar alt="Erick Subero" src={ProfilePicture} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '15px' }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={showActions}
                            onClose={handleActionToogle}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                        >
                        {actions.map((action) => (
                            <MenuItem key={action.name} href={action.link} target="_blank">
                                <Typography textAlign="center">{action.name}</Typography>
                            </MenuItem>
                        ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}