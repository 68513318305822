import React from 'react';
import '../css-files/privacy-policy.css';

export default function PutASmilePrivacyPolicy() {
	
    return (
        <div className="term-service-container">
            <p className='term-service-title'>Privacy Policy</p>
            <p className='term-service-subtitle'>Information Collection and Use</p>
            <p className='term-service-description'><b>Pakata Games</b> ("we" or "us") collects information about you when you use our mobile game <b>Put A Smile</b> (the "Game"). This information may include:</p>
            <ul className='term-service-list'>
                <li className='term-service-item'>Your device's unique identifier</li>
                <li className='term-service-item'>Your game progress</li>
                <li className='term-service-item'>Any other information you choose to provide us</li>
            </ul>
            <p className='term-service-description'>We use this information to operate and improve the Game, to track your progress, and to communicate with you. We may also use this information for advertising purposes.</p>
            
            <p className='term-service-subtitle'>Sharing of Information</p>
            <p className='term-service-description'>We may share your information with third-party service providers who help us operate the Game.</p>
      
            <p className='term-service-subtitle'>Data Security</p>
            <p className='term-service-description'>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no security system is perfect, and we cannot guarantee the security of your information.</p>

            <p className='term-service-subtitle'>Children's Privacy</p>
            <p className='term-service-description'>The Game is not directed to children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you believe that your child has provided us with personal information, please contact us at dracobk201@gmail.com</p>
            
            <p className='term-service-subtitle'>Your Choices</p>
            <p className='term-service-description'>You can choose to opt out of certain types of advertising by visiting the Network Advertising Initiative: https://www.networkadvertising.org/ or the Digital Advertising Alliance: https://www.digitaladvertisingalliance.org/ websites.</p>

            <p className='term-service-subtitle'>Changes to this Privacy Policy</p>
            <p className='term-service-description'>We may change this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we will notify you by email or by a notice in the Game.</p>

            <p className='term-service-subtitle'>Contact Us</p>
            <p className='term-service-description'>If you have any questions about this Privacy Policy, please contact us at</p>
            <p className='term-service-description'>dracobk201@gmail.com</p>
            <p className='term-service-effectiveness'>Effective Date: February 5, 2024</p>
        </div>
    );
}