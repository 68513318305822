import React, { useState, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import PutASmileTermService from './components/corporative/PutASmileTermService';
import PutASmilePrivacyPolicy from './components/corporative/PutASmilePrivacyPolicy';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

export default function App() {
	const [mode, setMode] = useState('light');

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  const changeMode = () => {
    setMode(mode === 'light' ? 'dark' : 'light');
  }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home changeMode={changeMode} />} />
            <Route path="/put-a-smile-terms-service" element={<PutASmileTermService />} />
            <Route path="/put-a-smile-privacy-policy" element={<PutASmilePrivacyPolicy />} />
            {/* <Route path="*" element={<NoPage />} /> */}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    );
}